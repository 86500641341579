import { useEffect, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import { useTable } from 'src/components/table';
import useFetcher, { endpoints } from 'src/utils/axios';
import { useDashboardContext, useRealTimeContext } from 'src/context/hooks';
import AlertComponent from 'src/sections/newMetadataTranslator/view/mt-table/translatables/alert-component';
import { IEnvironment } from 'src/types/orgCredential';
import { Container, Tooltip, Typography } from '@mui/material';
import { useSettingsContext } from '../settings';
import RetrieveListView from './retrieve-list-view';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  cred_id?: string;
  metadata_api_name_list?: string[];
  wizzardSetupType?: string;
  setWizzardUpdate?: (status: string) => void;
};

export default function MTRetrieveData({
  open,
  onClose,
  cred_id,
  metadata_api_name_list,
  wizzardSetupType,
  setWizzardUpdate,
}: Props) {
  const table = useTable({ defaultOrderBy: 'orderNumber', defaultRowsPerPage: 30 });
  const { postData, fetchData, updateData } = useFetcher();
  const { currentOrg } = useDashboardContext();
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [newWizzardStatus, setNewWizzardStatus] = useState(wizzardSetupType);
  const { realTimeMessages } = useRealTimeContext();
  const isDisabled = useBoolean();
  const settings = useSettingsContext();
  const theme = useTheme();
  useEffect(() => {
    if (open) {
      fetchEnv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cred_id, open]);

  useEffect(() => {
    if (realTimeMessages && currentOrg && open) {
      if (realTimeMessages[0]?.type === 'environment_refresh') {
        fetchEnv();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMessages]);
  const checkDisabled = (env: IEnvironment) => {
    if (env?.is_deploying || env?.is_translating || env?.is_retrieving) {
      isDisabled.onTrue();
    } else {
      isDisabled.onFalse();
    }
  };
  const fetchEnv = () => {
    fetchData([endpoints.environment, { params: { cred_id } }]).then((env) => {
      checkDisabled(env[0]);
      setEnvironment(env[0]);
    });
  };

  const handleUpdateWizzard = () => {
    if (currentOrg && setWizzardUpdate && wizzardSetupType) {
      const setupWizzardStatus = 'retrieveSetupFinished';
      const updatedOrg = { ...currentOrg, setupWizzardStatus };
      setNewWizzardStatus(setupWizzardStatus);
      setWizzardUpdate(setupWizzardStatus);
      updateData(endpoints.org.put, updatedOrg);
    }
  };

  const retrieveMetadata = () => {
    postData(
      [endpoints.metadata.retrieve, { params: { org_id: currentOrg?.org_id, cred_id } }],
      table.selected
    );
    handleUpdateWizzard();
    onClose();
  };

  useEffect(() => {
    if (metadata_api_name_list) {
      table.setSelected(metadata_api_name_list);
    }
  }, [metadata_api_name_list, table]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 720, minWidth: 300, height: '95%', maxHeight: 1150 } }}
    >
      <DialogTitle>Retrieve Metadata</DialogTitle>
      <DialogContent>
        <Box rowGap={3} columnGap={2} display="grid" sx={{ p: 2 }}>
          <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <AlertComponent environemt={environment} />
          </Container>
          <RetrieveListView table={table} disabled={isDisabled.value} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip
          title={
            <div style={{ padding: 4 }}>
              <ul
                style={{
                  lineHeight: '1.8',
                  margin: 0,
                  paddingLeft: '1.2em',
                  fontSize: '0.8rem',
                  paddingTop: 6,
                }}
              >
                <li>Select all Metadata you want to maintain for translation.</li>
                <li>After a successful retrieval, a notification should appear.</li>
                <li>
                  Retrieving metadata can take up to 15 minutes, depending on the amount of
                  metadata.
                </li>
              </ul>
            </div>
          }
          open={newWizzardStatus === 'retrieveTranslation' || false}
          arrow
          placement="top"
        >
          <LoadingButton
            type="submit"
            disabled={isDisabled.value}
            variant="contained"
            color="primary"
            onClick={retrieveMetadata}
          >
            Retrieve Metadata
          </LoadingButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}
