import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import { useSnackbar } from 'src/components/snackbar';
// config
import { HOST_API } from 'src/config-global';
import { useDashboardContext } from 'src/context/hooks';
import { ILog } from 'src/types/logs';
// ----------------------------------------------------------------------

export const axiosInstance = axios.create({ baseURL: HOST_API });
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
// ----------------------------------------------------------------------

const useFetcher = () => {
  const idToken = useAuthContext()?.currentUser?.accessToken;
  const org_id = useDashboardContext().currentOrg?.org_id;
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async (args: string | [string, AxiosRequestConfig]) => {
    try {
      const [url, config] = Array.isArray(args) ? args : [args];

      if (idToken) {
        const response = await axiosInstance
          .get(url, {
            ...config,
            headers: {
              Authorization: `Bearer ${idToken}`,
              ...config?.headers,
            },
            params: {
              org_id,
              ...config?.params,
            },
          })
          .catch((error) => {
            enqueueSnackbar(error.message, { variant: error.type });
          });
        if (response?.data.isPopup === true) {
          const log: ILog = response.data;
          enqueueSnackbar(log.message, { variant: log.type });
        }
        return response?.data;
      }
      const response = await axiosInstance.get(url, {
        ...config,
        headers: {
          ...config?.headers,
        },
        params: {
          ...config?.params,
        },
      });

      if (response.data.isPopup === true) {
        const log: ILog = response.data;
        enqueueSnackbar(log.message, { variant: log.type });
      }
      return response.data;
    } catch (err) {
      return err;
    }
  };
  const updateData = async (args: string | [string, AxiosRequestConfig], body: any) => {
    // try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const response = await axiosInstance
      .put(url, body, {
        ...config,
        headers: {
          Authorization: `Bearer ${idToken}`,
          ...config?.headers,
        },
        params: {
          org_id,
          ...config?.params,
        },
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: error.type });
      });
    if (response?.data.isPopup === true) {
      const log: ILog = response.data;
      enqueueSnackbar(log.message, { variant: log.type });
    }
    return response?.data;
  };

  const postData = async (args: string | [string, AxiosRequestConfig], body: any) => {
    const [url, config] = Array.isArray(args) ? args : [args];

    try {
      const response = await axiosInstance.post(url, body, {
        ...config,
        headers: {
          Authorization: `Bearer ${idToken}`,
          ...config?.headers,
        },
        params: {
          org_id,
          ...config?.params,
        },
      });

      // Handle any additional cases like popups
      if (response?.data.isPopup === true) {
        const log: ILog = response.data;
        enqueueSnackbar(log.message, { variant: log.type });
      }

      return response?.data;
    } catch (error) {
      console.error('Error in postData:', error);

      const errorMessage =
        error?.response?.data?.message || error.message || 'An unexpected error occurred.';
      const errorType = error?.response?.data?.type || 'error';

      enqueueSnackbar(errorMessage, { variant: errorType });

      return {
        status: 'error',
        message: errorMessage,
        type: errorType,
        response_code: error?.response?.status || 500,
      };
    }
  };

  // const postData = async (args: string | [string, AxiosRequestConfig], body: any) => {
  //   // try {
  //   const [url, config] = Array.isArray(args) ? args : [args];

  //   const response = await axiosInstance
  //     .post(url, body, {
  //       ...config,
  //       headers: {
  //         Authorization: `Bearer ${idToken}`,
  //         ...config?.headers,
  //       },
  //       params: {
  //         org_id,
  //         ...config?.params,
  //       },
  //     })
  //     .catch((error) => {
  //       console.log("error",error)
  //       return {
  //         status: 'error',
  //         message: "HEllo",
  //         type: 'Hello',
  //         response_code: error?.response?.status || 500,
  //         data:[]
  //       };
  //     });
  //   if (response?.data.isPopup === true) {
  //     const log: ILog = response.data;
  //     enqueueSnackbar(log.message, { variant: log.type });
  //   }
  //   return response?.data;
  // };
  const deleteData = async (args: string | [string, AxiosRequestConfig]) => {
    // try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const response = await axiosInstance
      .delete(url, {
        ...config,
        headers: {
          Authorization: `Bearer ${idToken}`,
          ...config?.headers,
        },
        params: {
          org_id,
          ...config?.params,
        },
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: error.type });
      });
    if (response?.data.isPopup === true) {
      const log: ILog = response.data;
      enqueueSnackbar(log.message, { variant: log.type });
    }
    return response?.data;
  };
  return { fetchData, updateData, postData, deleteData };
};

export default useFetcher;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  org: {
    get: '/org/getOrgs',
    put: '/org/updateOrg/',
    create: '/org/createOrg/',
    changeBillingUser: '/org/changeBillingUser/',
  },
  users: {
    mail_from_invite_key: '/user/mail_from_invite_key/',
    register_user: '/user/register_user/',
    password_reset: '/user/password_reset/',
    get: '/user/getUsers',
    getMe: '/user/getUser',
    invite_user: '/user/invite_user',
    login_with_provider: '/user/loginWithProvider/',
    salesforce_login: '/user/salesforceLogin',
    resend_user_invite: '/user/resend_user_invite',
    invitation_response: '/user/invitation_response/',
    deactivate: '/user/deactivateUsers',
    reactivate: '/user/reactivateUsers',
    changeRole: '/user/changeUsersRole',
    put: '/user/put/',
  },
  credential: {
    authorize: '/authentication/getAutherationUrl',
    salesforce_login_authorization: '/authentication/salesforceLoginAuth',
    setCreds: '/authentication/setCredentials',
    retrieveLanguages: '/authentication/retrieveLanguages',
    get: '/authentication/getCred',
    update: '/authentication/put/',
    delete: '/authentication/delete',
  },

  translatables: {
    get: '/translatables/get',
    put: '/translatables/put/',
    getInfo: '/translatables/getInfo',
    getInfoMTJob: '/translatables/getInfoMTJob',
    fuzzyLoadingMTJob: '/translatables/fuzzyGetMTJob/',
    fuzzyLoading: '/translatables/fuzzyGet/',
  },
  aiAssistants: {
    run: '/aiAssistant/run/',
  },
  metadata: {
    retrieve: '/metadata/retrieve/',
    deploy: '/metadata/deploy/',
  },
  glossary: {
    get: '/glossary/get',
    getSingleList: '/glossary/get/singleGlossaryList/',
    post: '/glossary/post/',
    delete: '/glossary/delete',
    put: '/glossary/put/',
    getList: '/glossary/get/fetchGlossaryList/',
    createList: '/glossary/post/createGlossaryList',
    updateList: '/glossary/put/updateGlossaryList/',
    deleteList: '/glossary/delete/deleteGlossaryList/',
    deleteManyList: '/glossary/delete/deleteManyGlossaryList/',
  },
  translateEngine: {
    get: '/translateEngine/get',
    getSingleTranslationEngine: '/translateEngine/getSingleTranslationEngine',
    post: '/translateEngine/post/',
    delete: '/translateEngine/delete',
    deleteMany: '/translateEngine/deleteMany/',
    put: '/translateEngine/put/',
    tokenHistory: '/translateEngine/translationEngineTokenHistory',
    testTranslation: '/translateEngine/testTranslation/',
    languageMappingPost: '/translateEngine/languagemapping/post',
    languageMappingGet: '/translateEngine/languagemapping/get',
    languageMappingGetAll: '/translateEngine/languagemapping/getAll',
    getOrgToken: '/translateEngine/getOrgToken',
  },
  translator: '/translator/translate/',
  engineTranslator: '/translator/translationJob/',
  aiTranslator: '/translator/AITranslation/',
  authenticate: '/translator/authenticate/',
  jobs: '/job/getjobs',
  MTJObs: {
    create: 'metadataTranslateJob/createMTJob/',
    getJob: 'metadataTranslateJob/getMTJob',
    update_status: 'metadataTranslateJob/updateStatusMTJob/',
    approve: 'metadataTranslateJob/approveMTJob/',
    deploy: 'metadataTranslateJob/deployJob/',
    getTranslatables: 'metadataTranslateJob/getMTJobTranslatables',
    addToMTJob: 'metadataTranslateJob/addToMTJob/',
    removeFromMTJob: 'metadataTranslateJob/removeFromMTJob/',
    getMTTranslatablesDetails: 'metadataTranslateJob/getMTJobDetailsData/',
    getInfo: 'metadataTranslateJob/getInfo',
    getMetadataApiName: 'metadataTranslateJob/getMetadataApiNames',
  },
  approval: {
    get: 'approval/get',
    put: 'approval/put/',
  },
  environment: 'environment/',
  logs: {
    getLogs: 'logs/getLogs',
    getTranslationLogs: 'logs/getTranslationLogs',
  },
  token: {
    create: 'token/create/',
    get: 'token/get',
    put: 'token/put/',
  },
  mail: 'mail/',
  stripe: {
    getSecurityKey: 'stripe/getSecurityKey',
    getCheckoutSession: 'stripe/getCheckoutSession',
    getCustomerPortal: 'stripe/getCustomerPortal',
  },
};
