import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
// routes
import { paths } from 'src/routes/paths';
import { FIREBASE_API } from "src/config-global";

const CookieBanner: React.FC = () => {
  const [isAnalyticsEnabled, setAnalyticsEnabled] = useState(false);

  useEffect(() => {
    if (isAnalyticsEnabled && FIREBASE_API.measurementId) {
      ReactGA.initialize(FIREBASE_API.measurementId);
    }
  }, [isAnalyticsEnabled]);

  const handleAccept = () => {
    setAnalyticsEnabled(true);
    localStorage.setItem("cookies-accepted", "true");
    if (FIREBASE_API.measurementId) {
      ReactGA.initialize(FIREBASE_API.measurementId);
    }
  };

  const handleReject = () => {
    setAnalyticsEnabled(false);
    localStorage.setItem("cookies-accepted", "false");
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      declineButtonText="Reject All"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleReject}
      style={{
        background: "#3564ca",
        color: "#FFF",
        textAlign: "center",
        padding: "10px",
        paddingRight: '6%',
        fontSize: "14px",
      }}
      buttonStyle={{
        color: "#4e503b",
        fontSize: "13px",
        background: "#FFF",
        borderRadius: "5px",
        padding: "5px 10px",
      }}
      declineButtonStyle={{
        color: "#FFF",
        background: "#232b35",
        borderRadius: "5px",
        padding: "5px 10px",
      }}
    >
      We use cookies to improve your experience and analyze website traffic. 
      By clicking &quot;Accept All&quot;, you consent to our use of cookies for analytics and other purposes.
      {" "}
      <a href={paths.legal.privacyPolicy} style={{ color: "#afdafa" }}>
        Learn More
      </a>
    </CookieConsent>
  );
};

export default CookieBanner;
